/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
    /* transform: translateY(-150%); */
}

body {
    font-family: 'Open Sans'
}

::-webkit-scrollbar {
    width: 6px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: white;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: rgb(44, 82, 130);
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(110, 162, 224);
  }
